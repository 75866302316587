import Box from '@gtg/components/box'
import Container from '@gtg/components/container'
import Seo from '@gtg/components/seo'
import media from '@gtg/utils/media-queries'
import React from 'react'
import styled from 'styled-components'
import ArticleLayout from '../components/articleLayout'
import Category from '../components/category'
import GlobalWrapper from '../components/globalWrapper'
import Subtitle from '../components/subtitle'
import Img1Src from '../images/articles/press/rheinmaintv-interview.jpg'
import Img3Src from '../images/articles/press/3.jpg'

const Img = styled.img`
  width: 100%;
  height: auto;
`

const H2 = styled.h2`
  margin-top: 4rem;
  ${media.md`
    margin-top: 3rem;
  `}
  ${media.xs`
    margin-top: 2rem;
  `}
`

const ArticlePage = () => {
  return (
    <GlobalWrapper>
      <Seo />
      <ArticleLayout>
        <Container wMax="sm">
          <Box px={[0, 0, 0, 3, 2, 1]} pyMax="lg">
            <Category>Presse</Category>
            <hr />
            <h1>Lenzbox in der Presse</h1>
            <Subtitle>Hier wird viel über die Lenzbox berichtet</Subtitle>
            <Img src="" />
            <p>
              Wir freuen uns über die große Resonanz in der Presse.
              <br />
              Hier ein paar ausgewählte Berichte:
            </p>
            <H2>Im Fernsehen</H2>
            <p>
              Am 29.03.2022 waren die Lenzbox-Gründer zu Gast bei{' '}
              <a
                href="https://www.rheinmaintv.de/sendungen/beitrag-video/start-up-talk-lenzbox-gmbh-mit-peter-bruhn--dr.-c.-doniga/vom-29.03.2022/"
                target="_blank"
                rel="noreferrer"
              >
                rheinmain tv.
              </a>
            </p>
            <Img src={Img1Src}></Img>
            <H2>Auf Messen</H2>
            <p>
              Am 02.09.2021 war Lenzbox-Gründer Peter Bruhn zu Gast auf dem{' '}
              <a
                href="https://hik.technologieland-hessen.de/hik2021-rueckblick"
                target="_blank"
                rel="noreferrer"
              >
                Hessischen Innovationskongress HIK2021.
              </a>
            </p>
            <iframe
              width="100%"
              height="450"
              src="//api.stream24.net/vod/getVideo.php?id=11985-1-11097&mode=iframe"
              title="HIK 2021"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture; encrypted-media"
              allowfullscreen
            />
            <H2>Im Internet</H2>
            <p>
              Im September 2021 wurden die Lenzbox-Gründer{' '}
              <a
                href="https://www.wiesbaden.de/wirtschaft/wirtschaftsstandort/content-gruender-az/lenzbox.php"
                target="_blank"
                rel="noreferrer"
              >
                von der Landeshauptstadt Wiesbaden portraitiert.
              </a>
            </p>
            <Img src={Img3Src} />
            <H2>Im Wettbewerb</H2>
            <p>
              Im Dezember 2021 belegte Lenzbox beim{' '}
              <a
                href="https://bernhard-schindler.de/2021/12/21/der-the-grow-chairmen-pitch-mit-sensationellem-gewinn/"
                target="_blank"
                rel="noreferrer"
              >
                THE GROW Chairmen Pitch den 2. Platz.
              </a>
            </p>
            <br />
            <Img src="https://bernhard-schindler.de/wp-content/uploads/2021/12/thegrow-chairmen-pitch_2000x1125.jpg" />
          </Box>
        </Container>
      </ArticleLayout>
    </GlobalWrapper>
  )
}

export default ArticlePage
